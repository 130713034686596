import React, { PureComponent } from 'react'
import { graphql } from 'gatsby'

import * as Modules from '../components/modules'
import * as Organisms from '../components/organisms'
import { css } from '@emotion/react'
import * as Libs from 'libs'

export class Tag extends PureComponent {
  //--------------------------------------
  //  Css
  //--------------------------------------

  contCss = () => css``

  constructor (props) {
    super(props)
    this.itemNum = 0
    this.counter = 0
  }
  componentDidMount () {
    Libs.Common.Config.excuteTransition()
  }
  render () {
    const { data, pageContext } = this.props
    const currentSlug = pageContext.slug
    const category = pageContext.category
    const tags = data.contentfulCommonTag

    const items = tags ? tags['content___post'] : null

    this.itemNum = items ? items.length : 0

    this.itemNum = items ? items.length : 0

    const getCategoryList = slug => {
      if (!items) return ''
      if (slug !== 'shop') {
        return (
          <Organisms.CategoryPage
            type={'category'}
            category={category}
            items={items}
            slug={currentSlug}
          />
        )
      } else {
        const variants = data.allShopifyProductVariant.edges

        return (
          <Modules.ProductsCategory
            lang={tags.node_locale}
            type={'category'}
            items={items}
            variants={variants}
            slug={currentSlug}
          />
        )
      }
    }

    return (
      <>
        <Libs.Common.SEO.SEO
          title={tags.name}
          lang={tags.node_locale}
          link={tags.slug}
          description={tags.description ? tags.description.raw : null}
        />

        <section
          css={this.contCss}
          className={'project-container init-animat-cont tags'}
          data-current={tags.slug}
          data-currentname={tags.name}
          data-type={'category'}
          data-categoryslug={currentSlug}
          data-categoryname={tags.name}
        >
          {getCategoryList(currentSlug)}
        </section>
      </>
    )
  }
}

export default Tag

export const pageQuery = graphql`
  query tagQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }

    contentfulCommonTag(id: { eq: $id }) {
      id
      node_locale
      name
      slug
      description {
        raw
      }
      group
      content___post {
        slug
        date
        name
        title

        node_locale
        category {
          slug
          name
        }
        tags {
          name
          slug
          group
        }
        thumbnail {
          gatsbyImageData(width: 600, quality: 70)
        }
        thumbnailSp {
          gatsbyImageData(width: 600, quality: 70)
        }
      }
    }
  }
`
